<template>
<div class="col-md-12">
      <div class="text-center text-main">
            <h1>Glömt lösenord</h1>
      </div>
      <b-container>
            <Stepper :steps="steps" :current="currentstep"></Stepper>
            <div class="card card-container">
                  <div v-if="!step1.complete">
                        <div class="p-2 m-4 text-center">
                              <Alert show variant="info" >Ange den e-postadress som du registrerat ditt konto med.</Alert>
                        </div>
                        <form name="form" @submit.prevent="SendEmail">
                              <Loader v-if="step1.loading" loadingtext="Vänta..."></Loader>
                              <div v-else>
                                    <div class="form-group">
                                          <label for="email">Ange din registrerade e-postadress</label>
                                          <input v-model="step1.email" v-validate="'required|email|max:100'" type="email" class="form-control" name="email" placeholder="Epostadress" />
                                          <Alert v-if="!step1.success && errors.has('email')" variant="danger" >Ange en giltig e-postadress</Alert>
                                    </div>
                                    <div class="form-group">
                                          <button class="btn btn-primary btn-block">
                                                <span>Nästa</span>
                                          </button>
                                    </div>
                                    <div class="form-group">
                                          <router-link class="btn btn-secondary w-100" to="/loggain">Avbryt</router-link>
                                    </div>
                              </div>
                        </form>
                  </div>
                  <div v-if="step1.complete && !step2.complete" class="text-center">
                        <Loader v-if="codeEntered" loadingtext="Kontrollerar koden..."></Loader>
                        <div v-else>
                              <p class="font-italic">Ange koden som skickades till din e-postadress {{step1.email}}</p>
                              <Alert variant="clock" >Du har 5 felaktiga försök eller max 30 minuter på dig</Alert>
                              <div v-if="step2.tries >= 5">
                                    <Alert show variant="danger" >Du har uppnått max antal försök!</Alert>
                              </div>
                              <div v-else>
                                    <div class="mt-4 mb-4">
                                          <PincodeInput v-model="step2.code" placeholder="_" :length="6" />
                                    </div>
                                    <div class="mt-2" v-if="step2.message">
                                          <Alert variant="danger" >{{step2.message}}</Alert>
                                    </div>
                              </div>
                              <b-button class="mt-2 btn-block" @click.prevent.stop="Cancel()">Avbryt</b-button>
                        </div>
                  </div>
                  <div v-if="step1.complete && step2.complete && !step3.complete" class="text-center">
                        <Loader v-if="step3.loading" loadingtext="Sparar lösenord..."></Loader>
                        <div v-else>
                              <p class="font-italic font-small">Ange ditt nya lösenord vid inloggning med {{step1.email}}</p>
                              <div class="form-group">
                                    <label for="password">Ange ditt nya lösenord</label>
                                    <input v-model="step3.password" v-validate="'required|min:8|max:40'" type="password" class="form-control" name="password" />
                                    <Alert v-if="errors.has('password')" variant="danger" >Ange ett giltigt lösenord</Alert>
                              </div>
                              <div class="form-group">
                                    <label for="repassword">Ange lösenord på nytt</label>
                                    <input v-model="step3.repassword" v-validate="'required|min:8|max:40'" type="password" class="form-control" name="repassword" />
                                    <Alert v-if="errors.has('repassword')" variant="danger" >Ange ett giltigt lösenord</Alert>
                              </div>
                              <div class="mt-2" v-if="step3.password != step3.repassword">
                                    <Alert variant="danger" >Lösenorden matchar inte!</Alert>
                              </div>

                              <div class="form-group">
                                    <button class="btn btn-primary btn-block" @click="SavePassword">
                                          <span >Spara</span>
                                    </button>
                              </div>
                        </div>
                  </div>
                  <div v-if="step1.complete && step2.complete && step3.complete" class="text-center">
                        <Alert variant="success" class="text-center" >Ditt lösenord är nu ändrat!</Alert>
                        <div>
                              <router-link class="btn btn-primary" to="/loggain">Logga in</router-link>
                        </div>
                  </div>
            </div>

      </b-container>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const Stepper = () => import("@/components/stepper/Stepper");
import PincodeInput from 'vue-pincode-input';

export default {
      name: 'ForgotPassword',
      components: {
            Alert,
            PincodeInput,
            Loader,
            Stepper
      },
      metaInfo: {
            title: 'Glömt lösenord',
            meta: [{
                  name: 'description',
                  content: "Om du har glömt ditt lösenord kan du återställa inloggningsuppgifterna genom att följa instruktionerna"
            }]
      },
      data() {
            return {
                  steps: [{
                              index: 0,
                              title: "Epost",
                              subtitle: "Ange din epostadress",
                              icon: "common/mail.svg"
                        },
                        {
                              index: 1,
                              title: "Kod",
                              subtitle: "Ange kod",
                              icon: "common/key.svg",
                        },
                        {
                              index: 2,
                              title: "Lösenord",
                              subtitle: "Ange nytt lösenord",
                              icon: "common/lock.svg"
                        }
                  ],
                  step1: { // epost
                        email: '',
                        loading: false,
                        success: false,
                        complete: false
                  },
                  step2: {
                        code: '',
                        loading: false,
                        success: false,
                        message: '',
                        complete: false,
                        tries: 0
                  },
                  step3: {
                        password: '',
                        repassword: '',
                        loading: false,
                        success: false,
                        complete: false
                  }
            };
      },
      computed: {
            loggedIn() {
                  return this.$store.state.auth.status.loggedIn;
            },
            codeEntered() {
                  // kolla att alla fält är ifyllda samt endast en sifra
                  var valid = this.step2.code.length == 6;
                  if (valid && !this.step2.loading) {
                        this.step2.loading = true;
                        this.validateEnteredCode(this.step2.code);
                  }
                  return valid;
            },
            currentstep() {
                  if (!this.step1.complete)
                        return 0;
                  if (this.step1.complete && !this.step2.complete)
                        return 1;
                  if (this.step1.complete && this.step2.complete && !this.step3.complete)
                        return 2;
                  return 3;
            }
      },
      mounted() {
            if (this.loggedIn) {
                  this.$router.push('/profil');
            }
            else {
                  if(this.$route.query["email"]){
                        this.step1.email = this.$route.query["email"];
                  }
            }
      },
      methods: {
            SendEmail() {
                  this.$validator.validate().then(isValid => {
                        if (isValid) {
                              this.step1.loading = true;
                              this.$store.dispatch('forgotpassword/forgotpassword', this.step1.email).then(() => {
                                    this.step1.complete = true;
                                    this.step1.success = true;
                                    this.step1.loading = false;
                              }).catch((error) => {
                                    this.step1.complete = false;
                                    this.step1.success = error;
                                    this.step1.loading = false;
                              });
                        }
                  });
            },
            validateEnteredCode(code) {
                  // ajax!
                  var email = this.step1.email;
                  this.step2.loading = true;
                  this.$store.dispatch("forgotpassword/validateForgotCode", {
                        email: email,
                        code: code
                  }).then(() => {
                        this.step2.complete = true;
                        this.step2.success = true;
                        this.step2.loading = false;
                        this.step2.tries = 0;
                  }).catch((error) => {
                        this.step2.loading = false;
                        this.step2.complete = false;
                        this.step2.success = false;
                        this.step2.code = "";
                        this.step2.tries += 1;
                        this.step2.message = error.response.data;
                  });
            },
            SavePassword() {
                  // ajax!
                  var email = this.step1.email;
                  var code = this.step2.code;
                  this.step3.loading = true;
                  this.$store.dispatch("forgotpassword/setnewpassword", {
                        email: email,
                        password: this.step3.password,
                        code: code
                  }).then(() => {
                        this.step3.complete = true;
                        this.step3.success = true;
                        this.step3.loading = false;
                  }).catch(() => {
                        this.step3.loading = false;
                        this.step3.complete = false;
                        this.step3.success = false;
                  });
            },
            svg(path) {
                  return this.$images.svg(path);
            },
            Cancel(){
                  this.$router.push("/");
            }
      }
};
</script>
